
import GC from '@grapecity/spread-sheets'
import "@grapecity/spread-sheets-print";
import * as ExcelIO from '@grapecity/spread-excelio'
import { saveAs } from "file-saver"
GC.Spread.Sheets.LicenseKey = (ExcelIO as any).LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX';
const useExportExcel = (workbook: GC.Spread.Sheets.Workbook, fileName: string) => {
    const times = new Date( +new Date() + 8 * 3600 * 1000 ).toJSON().substr(0,19).replace('T', '').replace(/-/g, '').replace(/:/g, '').substr(0, 12)
    const excelIo = new ExcelIO.IO();
    const json = workbook.toJSON({
        columnHeadersAsFrozenRows: true,
        includeAutoMergedCells: true
    });
    excelIo.save(
        json,
        function (data: any) {
            saveAs(data, fileName + '-' + times);
        }
    );
}
export default useExportExcel